import {
  Button,
  FileInput,
  Flex,
  Radio,
  Stack,
  Text,
  TextInput,
  Textarea,
  Title,
} from '@mantine/core';
import { isNotEmpty, useForm } from '@mantine/form';
import { notifications } from '@mantine/notifications';
import { IconCheck, IconMail, IconX } from '@tabler/icons-react';
import React, { useEffect, useState } from 'react';
import apiHelper from '../../utils/api';
import useLoader from '../common/hooks/useLoader.hook';

const renewalEmailContent =
  "Attached is the bus renewal application form for year 2025. We seek your assistance to complete the form by 8 Nov 2024. Please do amend the information in the form if it is incorrect or not updated. Kindly read and acknowledge the terms and condition in Annex B.\n\nIf you are withdrawing from the school bus service, please drop us an email to advise at smps@rstransport.com.sg\n\nBased on the 2024 transport address and trip arrangement, the adjusted bus fee for 2025 is {{ fare }}. This fee is calculated based on the distance traveled to and/or from the school address located at 136 Sophia Rd. If there are any changes to your 2024 transport arrangement, we will provide a revised 2025 fee.\n\nTo confirm and secure a seat for your child, a one-month retainer fee will be collected upon renewal registration. This retainer fee will be used to offset the final month's bus fee in 2025. The renewal process is only complete after we receive the retainer fee.\n\nPlease transfer the retainer fee {{ fare }} to our company DBS current account 023-900636-1 or PayNow to UEN 53006215ADBS. Do remember to enter your child's name in the remarks.\n\nPlease email us the renewal form together with the screenshot of retainer fee transfer confirmation.";

const ccaBusContent =
  "Attached is the CCA bus application form for year 2025. If you require this additional 4.30pm bus service, kindly complete this form and return to us by 8 Jan 2025.\n\nPlease transfer the T1 CCA Bus Fee to our company DBS Current Account 023-900636-1 or PayNow to UEN 53006215ADBS. Do remember to enter your child's name in the remarks.\n\nPlease email us the CCA form together with the screenshot of the transfer confirmation.";

const busEmailContent =
  'We are pleased to provide school bus service for your child {{ childName }}.\n\n{{ morningBusSection }}\n\n{{ afternoonBusSection }}\n\nIf you require further information related to your pick-up/drop-off time, please contact the bus driver directly for a quicker response. \n\nWe wish you and your child all the best for the upcoming school year!';

const busDetailsContent = `{{ busTime }} bus details are as follows:
Bus Number: {{ busNum }}
Bus License Plate: {{ busPlate }}
Driver Name: {{ driverName }}
Driver Mobile Number: {{ driverMobile }}`;

const BroadcastForm = () => {
  const [emailFile, setEmailFile] = useState<File | null>(null);
  const [attachmentFile, setAttachmentFile] = useState<File | null>(null);
  const { isLoading, showLoader, hideLoader } = useLoader();

  const form = useForm({
    initialValues: {
      school: '',
      broadcastType: '',
      subject: '',
      header: '',
      content: '',
    },
    validate: {
      school: isNotEmpty('Please select a school'),
      broadcastType: isNotEmpty('Please select a broadcast type'),
      subject: isNotEmpty('Please enter a subject'),
      header: isNotEmpty('Please enter a header'),
    },
    validateInputOnBlur: true,
  });

  useEffect(() => {
    switch (form.values.broadcastType) {
      case 'renewal':
        form.setValues({
          content: renewalEmailContent,
          subject: 'Renewal of Bus Service for Y2025 for {{ childName }}',
          header: 'Renewal',
        });
        break;
      case 'bus':
        form.setValues({
          content: busEmailContent,
          subject: 'Bus Details for {{ childName }}',
          header: 'Bus Details',
        });
        break;
      case 'cca':
        form.setValues({
          content: ccaBusContent,
          subject:
            'Additional 4.30pm Bus Service for Y2025 for {{ childName }}',
          header: 'CCA Bus Application Form for Y2025',
        });
    }
  }, [form.values.broadcastType]);

  const handleSubmit = async () => {
    if (form.values.broadcastType === 'bus' && attachmentFile == null) {
      notifications.show({
        message:
          'Please upload the bus details file using the email attachment field',
        color: 'red',
        icon: <IconX />,
        autoClose: false,
      });
      return;
    }
    if (emailFile == null) {
      notifications.show({
        message: 'Please upload the CSV file with the recipient emails',
        color: 'red',
        icon: <IconX />,
      });
    } else {
      showLoader();
      // prepare data for request
      const formData = new FormData();
      formData.append('emailFile', emailFile, 'RegBus.csv');
      if (attachmentFile != null)
        formData.append('attachmentFile', attachmentFile, attachmentFile.name);
      formData.append('form', JSON.stringify(form.values));

      try {
        const response = await apiHelper.broadcastEmail(formData);
        console.log(response);

        if (response.ok) {
          form.reset();

          hideLoader();
          notifications.show({
            title: 'Broadcast Emails Sent!',
            message: 'You should receive a status email shortly.',
            icon: <IconCheck />,
            autoClose: 5000,
            withCloseButton: true,
          });
        } else {
          hideLoader();
          const res = await response.json();
          notifications.show({
            message: res.message,
            color: 'red',
            icon: <IconX />,
            autoClose: false,
          });
        }
        setEmailFile(null);
        setAttachmentFile(null);
      } catch (err) {
        hideLoader();
        console.log(err);
        let message = 'An error has occurred. Please try again later.';
        if (err instanceof Error) {
          message = err.message;
        }
        notifications.show({
          title: 'An error has occurred!',
          message: message,
          color: 'red',
          icon: <IconX />,
        });
      }
    }
  };

  return (
    <>
      <Title>Mass Broadcast Emails</Title>
      <Text>
        Please enter the subject line, email content and upload any attachment
        (optional) as needed.
      </Text>
      <Stack
        pos={'relative'}
        spacing={'10px'}
        sx={(theme) => ({
          backgroundColor:
            theme.colorScheme === 'dark'
              ? theme.colors.dark[8]
              : theme.colors.gray[0],
        })}
      >
        <form onSubmit={form.onSubmit(handleSubmit)}>
          <Radio.Group
            py="lg"
            name="broadcastType"
            label="Broadcast Type"
            error="Please choose broadcast type"
            required={true}
            {...form.getInputProps('broadcastType')}
          >
            <Radio value="renewal" label="Renewal" />
            <Radio value="bus" label="Bus Details" />
            <Radio value="cca" label="CCA" />
          </Radio.Group>

          <Radio.Group
            py="lg"
            name="school"
            label="School"
            error="Please select school"
            required={true}
            {...form.getInputProps('school')}
          >
            <Radio value="SJI" label="SJI Junior" />
            <Radio value="SAJS" label="St Andrew's" />
            <Radio value="SMPS" label="St Margaret's " />
            <Radio value="KHS" label="Kong Hwa School" />
          </Radio.Group>

          <TextInput
            py="lg"
            placeholder="Enter Subject Line"
            description="{{ childName }} is a placeholder and will be replaced with actual child name when email is sent"
            label="Subject"
            required={true}
            {...form.getInputProps('subject')}
          />

          <TextInput
            py="lg"
            placeholder="Enter Email Header"
            label="Email Header"
            description="Text shown at the top of email"
            required={true}
            {...form.getInputProps('header')}
          />
          <Textarea
            placeholder="Email Content"
            label="Email Content"
            required={true}
            autosize
            {...form.getInputProps('content')}
          />

          {form.values.broadcastType === 'bus' && (
            <Textarea
              label="Bus Section Content"
              description="This field is for your reference only and cannot be edited."
              autosize
              readOnly
              value={busDetailsContent}
            />
          )}

          <FileInput
            py="lg"
            placeholder="Select CSV File"
            label="CSV File With Recipient Email Addresses"
            value={emailFile}
            required={true}
            accept="text/csv"
            onChange={setEmailFile}
          ></FileInput>

          <FileInput
            py="lg"
            placeholder="Select File"
            label="Email Attachment"
            value={attachmentFile}
            onChange={setAttachmentFile}
          ></FileInput>

          <Flex
            gap="md"
            justify="center"
            align="center"
            direction="row"
            wrap="wrap"
          >
            <Button
              disabled={isLoading}
              type="submit"
              leftIcon={<IconMail size="20" />}
            >
              Send Emails
            </Button>
          </Flex>
        </form>
      </Stack>
    </>
  );
};

export default BroadcastForm;
